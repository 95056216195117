import * as zenscroll from "zenscroll";
import { setEqualHeights } from "./setEqualHeights";

/**
 * @description This will find the parent by walking the parent nodes until it finds the root parent.
 * @param {HTMLElement} el The element that triggered the event. Should be a button.
 * @param {string} parentClass classname for the top level parent.
 */
const findParent = (el, parentClass) => {
  let parent = el.parentElement;

  while(!parent.classList.contains(parentClass)) {
    parent = parent.parentElement;
  }

  // if root node was not found, revert to old logic by selecting the first parent.
  if (parent === null) {
    return el.parentElement;
  }

  return parent;
}

/**
 * @description set the page url to the target id
 * @param {string} targetID target id
 */
const updatePageHash = (targetID) => {
  if (history.replaceState) {
    history.replaceState(null, null, `#${targetID}`);
  }
  else {
    location.hash = `#${targetID}`;
  }
}

function hideElement(el) {
  el.setAttribute("hidden", "");
  el.setAttribute("aria-hidden", "true");
  el.style.display = "none";
}

function showElement(el) {
  el.removeAttribute("hidden");
  el.removeAttribute("aria-hidden");
  el.style.display = "block";
}

function collapsible(e, contentElement = undefined) {
  const element = contentElement 
    ? findParent(contentElement, "b-collapsible").querySelector(".b-collapsible__button") 
    : e.currentTarget;
  const theParent = findParent(element, "b-collapsible");
  const targetID = element.getAttribute("aria-controls");
  const targetElement = document.getElementById(targetID);

  // Toggle active collapsible class on 'collapsible' button(s)
  const allButtons = theParent.querySelectorAll(`.b-collapsible__button[aria-controls="${targetID}"]`);
  Array.prototype.slice.call(allButtons).forEach(button => {
    const expanded = button.getAttribute("aria-expanded") === "true";
    if (!contentElement || (contentElement && !expanded)) {
      // Toggle expanded value
      button.setAttribute("aria-expanded", String(!expanded));
      const elemClasses = button.classList;

      if (elemClasses.contains("b-collapsible__button--active")) {
        elemClasses.remove("b-collapsible__button--active");
      } else {
        elemClasses.add("b-collapsible__button--active");
      }

      toggleContent(targetElement, expanded);
    }
    scrollToElement(expanded, element, contentElement === undefined);
  });

  // The subheading element that renders as a 'p' tag, non-clickable
  const subheadingEl = theParent.querySelectorAll(".b-collapsible__meta-heading")[0];
  // The subheading element that renders as a 'div', containing the nested collapsible markup
  const subheadingElCollapsible = theParent.querySelectorAll(".b-collapsible__subheading-collapsible")[0];

  // We want to add active state to the parent as well
  if (!contentElement && theParent.classList.contains("b-collapsible--active")) {
    theParent.classList.remove("b-collapsible--active");
  } else {
    theParent.classList.add("b-collapsible--active");
    updatePageHash(targetID);
  }

  if (subheadingEl && subheadingElCollapsible) {
    if (expanded) {
      hideElement(subheadingElCollapsible);
      showElement(subheadingEl);
    } else {
      hideElement(subheadingEl);
      showElement(subheadingElCollapsible);
    }
  }
}

function findWrapper(child) {
  // If the parent is the component wrapper, then return it, so we can scroll it into view later.
  // If it is a child, then just return itself.
  if (child.parentElement.getAttribute("class") === "b-collapsible") {
    return child.parentElement;
  } else {
    return child;
  }
}

function toggleContent(content, expanded) {
  if (!content) {
    return;
  }
  if (expanded) {
    content.setAttribute("hidden", "");
    content.setAttribute("aria-hidden", "true");
  } else {
    content.removeAttribute("hidden");
    content.removeAttribute("aria-hidden");
    setEqualHeights(content);
  }
}

function scrollToElement(expanded, el, isElementClicked) {
    // Animates the scroll to the element, making sure the top of the expanding area is in the window view
  if (!expanded || !isElementClicked) {
    zenscroll.setup(null, 10);
    const elem = findWrapper(el);
    setTimeout(() => {
      zenscroll.to(elem);
    }, 200);
  }
}

export { collapsible };
