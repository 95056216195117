import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import debounce from 'lodash.debounce';
import shortid from 'shortid';
import Stickyfill from 'stickyfilljs';
import { detect } from 'detect-browser';
import createUniqueHeaders from './utils/createUniqueHeadersUtil';
import FocusTrap from 'focus-trap-react';
import Button from './Button';

// TODO: switch these with icons from static/icons folder, fetch from backend.
import FilterIcon from './icons/filter';
import ChevronIcon from './icons/chevron';
import CloseIcon from './icons/close';

// We need to iterate the parent elements to get the real offsetTop
const getOffsetTop = element => {
  let offsetTop = 0;
  while (element) {
    offsetTop += element.offsetTop;
    element = element.offsetParent;
  }
  return offsetTop;
};

// Looks at the scroll position updates the active heading state based on the position
function findActiveHeading(headings, scrollPos, setActiveHeading) {
  // 20px gives us some headroom above the heading, so it always becomes active when linked to
  const headingSpace = 20;

  // Makes the nodeList to an array of htmlElements
  const htmlHeadings = [...headings];
  const scrolledPastItems = htmlHeadings.filter(
    h => getOffsetTop(h) < scrollPos() + headingSpace
  );

  setActiveHeading(scrolledPastItems.length);
}

const hasItems = arr => (arr && arr.length ? true : false);

const linkClasses = ({
  small = false,
  active = false,
  children = false,
  parent = false
} = {}) =>
  cn({
    'b-section-sidebar__link': parent,
    'b-section-sidebar__link--small': small,
    'b-section-sidebar__link--active': active,
    'b-section-sidebar__link--children': children
  });

const subLinkClasses = ({ active = false, subheading = false } = {}) =>
  cn({
    'b-section-sidebar__sub-link': true,
    'b-section-sidebar__sub-link--subheading': subheading,
    'b-section-sidebar__sub-link--active': active
  });

const sectionSidebarClasses = ({ bottom, subpages = false, nonSticky = false }) =>
  cn({
    'b-section-sidebar': true,
    'b-section-sidebar--bottom': bottom,
    'b-section-sidebar--subpages': subpages,
    'b-section-sidebar--non-stick': nonSticky
  });

// Part of the component as it own component, we also make it use itself.
const ListItem = ({ props }) => {
  // On click, we find the corresponding heading
  // We add tabindex, so tabindex order isn't broken. Then we focus on it.
  const setFocus = () => {
    const heading = document.getElementById(props.url.replace('#', '')) || '';
    heading && heading.setAttribute('tabindex', -1);
    setTimeout(function () {
      heading && heading.focus();
    }, 0);
  };
  const renderItemContent = (props.prefix || props.description) && (
    <a className="b-section-sidebar__meta" href={props.url} onClick={setFocus}>
      {props.prefix && (
        <span className="b-section-sidebar__meta-prefix">{props.prefix}</span>
      )}
      {props.description && props.description}
    </a>
  );
  const itemChildren = props.children ?? props.subheadings;
  const renderItemContentChildren = (
    itemChildren?.length > 0 && <ol className='b-section-sidebar__list'>
      {itemChildren &&
        itemChildren.map(child => (
          <li>
            <a
              className={subLinkClasses({ active: child.active, subheading: itemChildren === props.subheadings })}
              key={shortid.generate()}
              href={child.url}
            >
              {child.description}
            </a>
          </li>
        ))}
      {props.readMoreLabel && (
        <span
          className={subLinkClasses({ active: false })}
          key={shortid.generate()}
        >
          {props.readMoreLabel}
        </span>
      )}
    </ol>
  );

  return (
    <li
      className={linkClasses({
        small: props.small,
        children: props.children,
        active: props.active,
        parent: true
      })}
    >
      {renderItemContent}
      {renderItemContentChildren}
    </li>
  );
};

export const SectionSidebar = props => {
  const [headings, setHeadings] = useState([]);
  const [activeHeading, setActiveHeading] = useState(0);
  const [bottom, setBottom] = useState(false);
  const sidebarRef = useRef(null);

  const nearBottom = () => {
    const position = window.pageYOffset;
    const documentHeight = document.body.scrollHeight;
    const windowHeight = window.innerHeight;
    // If we have scrolled to the bottom of (75% window height) of the page (body)
    if (position > documentHeight - windowHeight * 1.75) {
      setBottom(true);
    } else {
      setBottom(false);
    }
  };

  const getAllHeadings = () => {
    return headings.reduce((allHeadings, h) => {
      allHeadings.push(h.heading);
      if (h.subheadings.length > 0) {
        allHeadings.push(...h.subheadings);
      }
      return allHeadings;
    }, []);
  }

  useEffect(() => {
    // Fetches all headings on mount, if we don't have a list
    if (!hasItems(props.list) && !hasItems(headings)) {
      const headings1 = [...document.querySelectorAll('.t-body-text h2,h3')];
      const headings2 = [...document.querySelectorAll('.l-article h2,h3')];
      let allHeadings = new Set(headings1.concat(headings2));
      allHeadings = [...allHeadings];

      // Creates a list of objects, each containing associated h2 og h3 headings
      const elements = [];
      allHeadings.map(heading => {
        if (heading.nodeName == "H3") {
          elements[elements.length-1].subheadings.push(heading);
        } else {
          elements.push({
              heading: heading,
              subheadings: []
          });
        }
      });

      // Avoid infinite loop with zero headings
      if (elements.length > 0) {
        setHeadings(elements);
      }
    }
    if (hasItems(headings)) {
      Stickyfill.add(sidebarRef.current);
    }
    if (!hasItems(props.list)) {
      // Returns a new function that is a debounce with our function and its arguments
      const createDebounceFunction = (func, ...args) =>
        debounce(() => {
          func(...args);
        }, 16.66);

      // pageYOffset is not a function. To keep the fundActiveHeading function pure, we let it run the this function from an argument, so we can easily test it in the future.
      // setActiveHeading updates our state once it run in findActiveHeading. A function in an event listener can't return any value.
      const findActiveHeadingDebounce = createDebounceFunction(
        findActiveHeading,
        getAllHeadings(),
        () => window.pageYOffset,
        setActiveHeading
      );

      const nearBottomDebounce = createDebounceFunction(nearBottom);

      window.addEventListener('scroll', findActiveHeadingDebounce);
      // Use this fallback function only for IE
      // Even with sticky polyfill IE has problems with tall sticky elements
      if (detect() && detect().name === 'ie') {
        window.addEventListener('scroll', nearBottomDebounce);
      }

      return () => {
        window.removeEventListener('scroll', findActiveHeadingDebounce);
        if (detect() && detect().name === 'ie') {
          window.removeEventListener('scroll', nearBottomDebounce);
        }
      };
    }
  }, [props.list, headings.map(item => item.heading)]);

  // Gives all headings a url-safe id based on its text
  if (!hasItems(props.list) && hasItems(headings)) {
    // Util that create unique id for the h2 and h3 tags
    createUniqueHeaders(headings.map(item => item.heading));
    createUniqueHeaders(headings.flatMap(item => item.subheadings));
  }

  // Creates a list with links with either the headings, or the list it received
  // Bugfix for IE: Remove # symbol from text
  const list = !hasItems(props.list)
    ? headings.map(item => ({
      description: item.heading.innerText.replace('#', ''),
      url: `#${item.heading.id}`,
      subheadings: item.subheadings.map(subheading => ({
        description: subheading.innerText.replace('#', ''),
        url: `#${subheading.id}`,
      }))
    }))
    : props.list;
  
  const getHeadingIndex = (heading) => {
    return getAllHeadings().findIndex(h => heading.description === h.innerText);
  }

  const theList = [];
  const renderContent = () => (
    <>
      <div
        className={sectionSidebarClasses({ bottom, subpages: props.subpages, nonSticky: props.nonSticky })}
        ref={sidebarRef}
      >
        <div
          className={cn({
            'b-section-sidebar__heading': props.heading,
            'b-section-sidebar__heading--thick': !hasItems(props.list)
          })}
        >
          {props.icon && (
            <img
              src={props.icon}
              alt={props.iconAltText ? props.iconAltText : ''}
              role="presentation"
              className="b-section-sidebar__icon"
              aria-hidden
            />
          )}
          {props.heading && props.headingUrl ? (
            <a href={props.headingUrl} id="section-sidebar-heading">
              {props.heading}
            </a>
          ) : (
            <span id="section-sidebar-heading">{props.heading}</span>
          )}
        </div>

        <nav aria-describedby="section-sidebar-heading">
          {list.map((item) => {
            if (!hasItems(props.list)) {
              // Checks if the h2 heading or its associated h3 headings are active
              const index = getHeadingIndex(item);
              let headingActive = activeHeading === index + 1;
              if (item.subheadings.length > 0) {
                item.subheadings = item.subheadings.map(subheading => {
                  const subheadingIndex = getHeadingIndex(subheading);
                  const subheadingActive = subheadingIndex + 1 === activeHeading;
                  headingActive = !headingActive && subheadingActive ? true : headingActive;
                  
                  return {
                    ...subheading,
                    active: subheadingActive
                  }
                });
              }
              theList.push(
                <ListItem
                  props={{
                    ...item,
                    active: headingActive
                  }}
                  key={shortid.generate()}
                />
              );
            } else {
              theList.push(
                <ListItem
                  props={{
                    ...item
                  }}
                  key={shortid.generate()}
                />
              );
            }
            return null;
          })}
          <ol className='b-section-sidebar__list'>{theList}</ol>
        </nav>
      </div>
    </>
  );

  return (
    <>{hasItems(props.list) || hasItems(headings) ? renderContent() : null}</>
  );
};

/*
All of this bellow is just a huge mess. The reason for this is a tight deadline
*/

export const V2 = (props) => {

  const { list } = props

  const Root = (props) => {
    const {
      heading,
      headingUrl,
      icon,
      children,
      list,
    } = props

    const [open, setOpen] = useState(list.find(item => item.active)?.title || "")

    const getActiveHandler = (title) => {
      return () => {
        if (open === title) {
          setOpen("")
        } else {
          setOpen(title)
        }
      }
    }

    return <div className="b-section-sidebar-reports">
      <div className='l-mt-1'>
        {heading && <h2 className="h5">
          <a className='b-section-sidebar-reports__heading' href={headingUrl || ""}>
            {icon && <img
              src={props.icon}
              alt={props.iconAltText ? props.iconAltText : ''}
              role="presentation"
              className="b-section-sidebar__icon"
              aria-hidden
            />}
            {heading}
          </a>
        </h2>}
      </div>
      <ol className='b-section-sidebar-reports__root-list'>
        {React.Children.map(children, (child, index) => {
          return React.cloneElement(child, {
            open,
            setOpen: getActiveHandler(list[index].title),
          })
        })}
      </ol>
    </div>
  }

  const ListItemParent = (props) => {
    const { title, url, active, open, setOpen, prefix = "", children } = props
    const childIsActive = !!children?.find(child => child.props.active)

    useEffect(() => {
      if(childIsActive) {
        setOpen()
      }
    }, [])

    const isOpen = open === title
    return (
      <li key={title} className={cn('b-section-sidebar-reports__parent', { "b-section-sidebar-reports__parent--active": isOpen })}>
        <div className={cn('b-section-sidebar-reports__parent-link', {
          "b-section-sidebar-reports__parent-link--open": isOpen || childIsActive,
          "b-section-sidebar-reports__parent-link--active": active,
        })}>
          <a
            className={cn('b-section-sidebar-reports__parent-anchor', {
              'b-section-sidebar-reports__parent-anchor--active': isOpen,
            })}
            href={url}
          >{
            <span className="b-section-sidebar-reports__prefix">
              {prefix}
            </span>
            }
            {title}
          </a>
          {
            children?.length
              ? (<Button className='b-section-sidebar-reports__parent-toggle-button' clean onClick={setOpen}><ChevronIcon rotate={isOpen ? 180 : 0}/></Button>)
              : null
          }
        </div>

        {
          (children?.length && isOpen)
            ? (<ol className={cn('b-section-sidebar-reports__parent-list')}>
                {children}
              </ol>)
            : null
        }
      </li>
    )
  }

  const ListItemChild = (props) => {
    const { description, url, active = false, prefix } = props

    return <li key={description} className={cn('b-section-sidebar-reports__child', { "b-section-sidebar-reports__child--active": active })}>
      <a className={cn('b-section-sidebar-reports__child-anchor')} href={url}>
        <span className="b-section-sidebar-reports__prefix">
          {prefix}
        </span>
        {description}
      </a>
    </li>
  }

  // RENDER
  return <Root {...props}>
    {list.map((parentData) => {
      const { children, ...rest } = parentData
      return (
        <ListItemParent {...rest}>
          {children.map(childData => {
            return <ListItemChild {...childData} />
          })}
        </ListItemParent>
      )
    })}
  </Root>
}

export const StickyAside = (props) => {
  return (
    <nav className='b-section-sidebar-reports-sticky'>
      <V2 {...props} />
    </nav>
  )
}

export const MobileDialog = (props) => {
  const [open, setOpen] = useState(false)

  return (
    <div>
      <Button plain className='b-section-sidebar__dialog-open-button' onClick={() => setOpen(true)}>
        <FilterIcon /> <span>Innhold i rapporten</span>
      </Button>
      {open && <FocusTrap>
        <div className='b-section-sidebar__dialog' onClick={(e) => {
          e.preventDefault()
          setOpen(false)
        }}>
          <div onClick={(e) => e.stopPropagation()} className="b-section-sidebar__dialog-content">
            <Button className='b-section-sidebar__dialog-close-button' clean onClick={() => setOpen(false)}>
              Lukk <CloseIcon />
            </Button>
            <div className='b-section-sidebar__dialog-content2'>
              <V2 {...props} />
            </div>
          </div>
        </div>
      </FocusTrap>
      }
    </div>
  )
}

ListItem.propTypes = {
  description: PropTypes.string,
  prefix: PropTypes.string,
  url: PropTypes.string,
  readMoreLabel: PropTypes.string,
  small: PropTypes.bool,
  active: PropTypes.bool,
  children: PropTypes.node,
  subheadings: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      url: PropTypes.string.isRequired,
      active: PropTypes.bool
    })
  )
};

SectionSidebar.propTypes = {
  heading: PropTypes.string,
  headingUrl: PropTypes.string,
  icon: PropTypes.string,
  iconAltText: PropTypes.string,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      prefix: PropTypes.string,
      url: PropTypes.string.isRequired,
      readMoreLabel: PropTypes.string,
      active: PropTypes.bool,
      children: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          description: PropTypes.string,
          prefix: PropTypes.string,
          url: PropTypes.string.isRequired,
          active: PropTypes.bool,
        })
      )
    })
  ),
  subpages: PropTypes.bool,
  nonSticky: PropTypes.bool
};

export default SectionSidebar;
